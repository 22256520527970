<template>
  <AppointmentCard
    v-selectable-container="{
      callback: clickArrowAction.callback,
      elementLevel: 1
    }"
    :image="image"
    :title="title"
    :middle-line="middleLine"
    :active="activePrivate"
    :button-action="actionPrivate"
  />
</template>

<script>
import { AppointmentCard, selectableContainer } from '@seliaco/red-panda'

export default {
  name: 'ConferenceAppointmentCard',
  props: {
    conference: {
      id: String,
      name: String,
      start_date: String,
      duration: Number,
      status: String,
      price: Number,
      capacity: Number,
      ability: Number,
      specialist: {
        id: String,
        speciality: String,
        name: String,
        picture: String,
        user: {
          first_name: String,
          last_name: String,
          profile_picture: String
        }
      },
      room_link: String,
      cover: String
    },
    action: {
      text: String,
      callback: Function
    },
    active: {
      type: Boolean,
      default: true
    },
    noClickable: {
      type: Boolean,
      default: true
    }
  },
  components: {
    AppointmentCard
  },
  data () {
    return {
      arrowAction: null,
      activePrivate: this.active || true,
      actionPrivate: this.action ? { ...this.action } : null
    }
  },
  directives: { selectableContainer },
  computed: {
    image () {
      return this.conference.cover
    },
    title () {
      return this.conference.name
    },
    middleLine () {
      const startsAt = this.$moment(this.conference.start_date.split('+')[0])

      if (this.isAboutToStart()) {
        const fromNow = startsAt.fromNow()

        return `${this.$translations.general.starts} <span style="color: var(--purple)">${fromNow}</span>`
      }

      const date = this.$moment(
        this.conference.start_date.split('+')[0]
      ).format('D MMMM, ddd • h:mm a')

      const virtual =
        this.$translations.appointments.virtual[
          this.conference.room_link ? 'true' : 'false'
        ]

      return `${date} • ${virtual}`
    },
    clickArrowAction () {
      if (!this.noClickable) {
        return {
          callback: null
        }
      }

      return {
        callback: () => {
          this.$router
            .push({
              name: 'ConferenceDetail',
              params: {
                id: this.conference.id
              },
              query: {
                back: this.$route.fullPath
              }
            })
            .catch(() => {})
        }
      }
    }
  },
  methods: {
    isAboutToStart () {
      const before = this.$moment(this.conference.start_date).subtract(
        15,
        'minutes'
      )
      const after = this.$moment(this.conference.start_date).add(
        this.conference.duration,
        'minutes'
      )

      return this.$moment().isBetween(before, after)
    }
  },
  watch: {
    active: {
      handler (val) {
        this.activePrivate = val
      },
      immediate: true
    },
    action: {
      handler (val) {
        if (val) {
          this.actionPrivate = { ...val }
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="sass" scoped>
::v-deep .appointment-card
  grid-template-columns: auto

  &-content &-image
    border-radius: 16px
</style>
