<template>
  <div class="padding-y padding-x-zero">
    <div
      id="partialBackground"
      :class="{ 'partial-background': partialBackground }"
    >
      <!-- Title -->
      <div class="padding-x">
        <div class="heading-display text-purple" data-hj-suppress>
          {{ name }}
        </div>
      </div>

      <Wallet />

      <!-- Notifications -->
      <SeliaNotifications />
    </div>

    <DynamicContainer />

    <!-- Check-in Modal -->
    <CheckInModal
      v-if="showCheckInModal"
      :data="checkInModal"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { userStatus } from '@/types/state'
import { mapGetters } from 'vuex'

import DynamicContainer from '@/views/home/components/DynamicContainer'
import SeliaNotifications from '@/views/home/components/SeliaNotifications'
import CheckInModal from '@/views/check-in/modals/CheckInModal'
import Wallet from '@/views/home/components/Wallet'
import { RoleType } from '@seliaco/red-panda'

export default {
  components: {
    SeliaNotifications,
    DynamicContainer,
    CheckInModal,
    Wallet
  },
  data () {
    return {
      state: null,
      scrollValid: false,
      partialBackground: false,
      showCheckInModal: false
    }
  },
  mounted () {
    if (this.user) {
      this.init()
    } else {
      this.$store.watch(
        () => this.user,
        () => {
          this.init()
        }
      )
    }

    if (!localStorage.getItem('FirstLoginSuccess')) {
      localStorage.setItem('FirstLoginSuccess', JSON.stringify(true))
    }
  },
  methods: {
    init () {
      this.enableListeners()
      this.state = this.user.status
      if (this.notifications.checking) {
        this.$globalEvent.$emit('modal/check-in-dialog', {
          showDialog: { checkInModal: { open: true, close: false } }
        })
      }
    },
    /**
     * Listeners
     */
    enableListeners () {
      const container = document.getElementById('mainContainerContent')

      this.handleScroll(container.scrollTop)
      this.handleScroll(window.scrollY)

      window.addEventListener('scroll', this.handleScroll)
      container.addEventListener('scroll', this.handleScroll)
    },
    disableListeners () {
      const container = document.getElementById('mainContainerContent')
      const header = document.getElementById('mainContainerHeader')
      const body = document.getElementById('app')
      header.classList.remove('enable-transparent-header')
      body.classList.remove('enable-status-bar-bg')

      window.removeEventListener('scroll', this.handleScroll)
      container.removeEventListener('scroll', this.handleScroll)
    },
    handleScroll (event) {
      const toggle = event?.target?.scrollTop || window.scrollY
      const header = document.getElementById('mainContainerHeader')
      const body = document.getElementById('app')

      if (toggle >= 20) {
        if (!this.partialBackground) {
          header.classList.remove('enable-transparent-header')
        }
        body.classList.remove('enable-status-bar-bg')
      } else {
        if (!this.partialBackground) {
          header.classList.add('enable-transparent-header')
        }
        body.classList.add('enable-status-bar-bg')
      }
    },
    closeModal () {
      if (this.role === RoleType.USER_HIDE_PRICES) {
        this.$router.replace({
          name: 'Search'
        })
      } else {
        this.$router.replace({
          query: {
            ...this.$route.query,
            showModal: 'invisible'
          }
        })
      }
      this.showCheckInModal = false
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      role: 'auth/role',
      notifications: 'auth/notifications'
    }),
    name () {
      return this.$translations.home.hello.replace(
        '{UserName}',
        this.user.first_name
      )
    },
    title () {
      return this.state === userStatus.active
        ? this.$translations.home['title-active']
        : this.$translations.home.title
    },
    checkInModal () {
      return this.$route.query.showModal === 'visible'
        ? JSON.parse(this.$route.query.data)
        : []
    }
  },
  beforeRouteLeave (to, from, next) {
    this.disableListeners()

    next()
  },
  watch: {
    '$route.query': {
      handler () {
        if (this.$route.query.showModal === 'visible') {
          this.showCheckInModal = true
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="sass" scoped>
$header-size: 52px
$container-padding: 24px

.partial-background
  position: relative

  *
    position: relative
    z-index: 1

  &::before
    z-index: 0
    content: ""
    height: 100vh
    position: absolute
    width: 100%
    bottom: 48px
    background-image: linear-gradient(to top right,#E6DCFA, #E5F3FC)

    @media (min-width: 1240px)
      width: calc(100% + 200px)
      margin: 0 -100px

@media (min-width: 1268px)
  .padding-x
    padding-left: 0!important
</style>
