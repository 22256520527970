<template>
  <HomeSkeleton v-if="loading" />
  <div class="dynamic-home" v-else>
    <template v-for="(item, index) of options">
      <template v-if="item.hasSegment">
        <div class="segment" :key="index">
          <div class="segment-content">
            <component
              :is="item.component"
              v-if="item.show"
              v-bind="item.props"
              @action="handlerShortcutType"
            />
          </div>
        </div>
      </template>
      <template v-else>
        <component
          :key="index"
          :is="item.component"
          v-if="item.show"
          v-bind="item.props"
          @action="handlerShortcutType"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { RoleType, ShortcutsSection } from '@seliaco/red-panda'
import { split } from '@seliaco/growthbook'
import { Browser } from '@capacitor/browser'

import HomeSpecialities from '@/views/home/components/Specialities'
import HomeConferences from '@/views/home/components/Conferences'
import HomeNextConference from '@/views/home/components/NextConference'
import MoodBanner from '@/views/diary/components/MoodBanner'
import ServicesCarrousel from '@/views/home/components/ServicesCarrousel'
import CrossellingCarrousel from '@/views/home/components/CrossellingCarrousel'
import ContentLibrary from '@/views/home/components/ContentLibrary'
import NextAppointment from '@/views/home/components/NextAppointment/v2/NextAppointment'
import HomeSkeleton from '@/views/home/components/HomeSkeleton'
import Programs from '@/views/home/components/Programs'

import PackagePromotionBanner from '@/views/promos/packages/components/PackagePromotionBanner'

import { SupportEvent, HomeEvent, ProgramsEvent } from '@/types/events'
import { userStatus } from '@/types/state'

export default {
  name: 'DynamicContainer',
  components: {
    ShortcutsSection,
    ContentLibrary,
    HomeSpecialities,
    HomeConferences,
    HomeNextConference,
    MoodBanner,
    ServicesCarrousel,
    CrossellingCarrousel,
    PackagePromotionBanner,
    NextAppointment,
    HomeSkeleton,
    Programs
  },
  data () {
    return {
      state: null,
      appointment: null,
      options: [],
      loading: true
    }
  },
  created () {
    this.loadStoreWatch()
    this.$store.dispatch('programs/getPrograms')
  },
  methods: {
    loadStoreWatch () {
      if (
        this.config &&
        (this.nextAppointment?.appointment || this.nextAppointment?.specialist)
      ) {
        this.getComponents()
        this.appointment = this.nextAppointment
        this.loading = false
      } else {
        this.$store.watch(
          () => [this.config, this.nextAppointment],
          () => {
            this.getComponents()
            this.appointment = this.nextAppointment
            this.loading = false
          }
        )
      }

      if (this.user) {
        this.init()
      } else {
        this.$store.watch(
          () => this.user,
          () => {
            this.init()
          }
        )
      }
    },
    init () {
      this.$store.dispatch('dynamicHome/getHomeData')
      this.$store.dispatch('myAppointments/get')
      this.state = this.user.status
    },
    getComponents () {
      let options = [
        {
          component: CrossellingCarrousel,
          position: this.config?.BANNER_CROSSELLING?.position,
          show: this.config?.BANNER_CROSSELLING
        },
        {
          component: ShortcutsSection,
          position: this.config?.SHORTCUTS?.position,
          show: this.config?.SHORTCUTS,
          props: {
            store: this.$store,
            lang: this.$store.getters.language.lang
          }
        },
        {
          component: NextAppointment,
          show: this.config?.NEXT_APPOINTMENT,
          position: this.config?.NEXT_APPOINTMENT?.position,
          props: {
            'user-state': this.state
          }
        },
        {
          component: Programs,
          show: this.config?.PROGRAMS,
          position: this.config?.PROGRAMS?.position,
          props: {
            action: {
              text: this.$translations.general['see-more'],
              callback: () => {
                ProgramsEvent.Programs_Button_Clicked({
                  origin: 'Home - Programs section',
                  destination: 'Programs'
                })
                this.$router.push({
                  name: 'Programs'
                })
              }
            }
          }
        },
        {
          component: MoodBanner,
          hasSegment: true,
          props: {
            'label-style': 'gray',
            back: 'Home'
          },
          show: this.config?.MY_DIARY,
          position: this.config?.MY_DIARY?.position
        },
        {
          component: PackagePromotionBanner,
          hasSegment: true,
          props: {
            'discount-percent': 12,
            origin: 'home',
            'background-color': 'white',
            specialist: this.nextAppointment.specialist,
            extended: !this.nextAppointment.specialist
          },
          show: this.config?.PACKAGES,
          position: this.config?.PACKAGES?.position
        },
        {
          component: this.showCarrousel ? ServicesCarrousel : HomeSpecialities,
          show: this.config?.SLIDE_SEARCH_SPECIALISTS,
          position: this.config?.SLIDE_SEARCH_SPECIALISTS?.position
        },
        {
          component: HomeConferences,
          show: this.config?.NEXT_EVENTS,
          position: this.config?.NEXT_EVENTS?.position
        },
        {
          component: HomeNextConference,
          show: this.config?.NEXT_EVENTS,
          position: this.config?.NEXT_EVENTS?.position
        },
        {
          component: ContentLibrary,
          show: this.config?.FOR_ME,
          position: this.config?.FOR_ME?.position
        }
      ]

      if (RoleType.USER_HIDE_PRICES === this.role) {
        const componentsDisabled = [
          HomeConferences,
          PackagePromotionBanner,
          HomeNextConference,
          Programs
        ]

        options = options.filter(
          (c) => !componentsDisabled.includes(c.component)
        )
      }

      this.options = options.sort((a, b) => a.position - b.position)
    },
    callSegmentEvent (key) {
      const body = {
        user: this.user,
        icon: key
      }
      HomeEvent.Home_Icon_Click(body)
    },
    handlerShortcutType (key) {
      this.callSegmentEvent(key)
      switch (key.type) {
        case 'EVENT':
          this.handlerShortcutAction(key.action)
          break
        case 'INTERNAL_REDIRECT':
          if (key.action.includes('search')) {
            this.$router.push({
              path: key.action,
              query: {
                directory_origin: 'ShorcutSection'
              }
            })
          } else {
            this.$router.push({
              path: key.action
            })
          }
          break
        case 'EXTERNAL_REDIRECT':
          Browser.open({ url: key.action })
          break
      }
    },
    handlerShortcutAction (key) {
      switch (key) {
        case 'SCHEDULE':
          this.handleSchedule()
          break
        case 'SPECIALISTS':
          this.$router.push({
            name: 'MySpecialists',
            query: {
              back: 'Home',
              tab: 'history'
            }
          })
          break
        case 'SUPPORT':
          this.$kustomer.openChat(this.$store.getters.platform)
          SupportEvent.clickSupport({
            user: this.user,
            source: 'Home'
          })
          break
        case 'CHECK-IN':
          this.$router.push({
            name: 'CheckIn',
            query: {
              type: 'WELLNESS'
            }
          })
          break
        case 'DIARY':
          this.$router.push({
            name: 'Diary'
          })
          break
      }
    },
    handleSchedule () {
      const stateDisallow = [
        userStatus.account_created,
        userStatus.waiting_for_orientation
      ]

      if (this.appointment?.specialist && !stateDisallow.includes(this.state)) {
        this.$globalEvent.$emit('modal/schedule', {
          showDialog: { schedule: { open: true, close: false } }
        })
      } else {
        this.$globalEvent.$emit('modal/start', {
          showDialog: { start: { open: true, close: false } }
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      nextAppointment: 'nextAppointment',
      config: 'dynamicHome/getHomeData',
      role: 'auth/role'
    }),
    showCarrousel () {
      return !!split.growthbook.isOn('services-carrousel')
    }
  }
}
</script>
