<template>
  <div class="padding-x padding-top">
    <Heading
      :text="$translations.home['search-specialist'].title"
      :action="actions.viewMore"
    />

    <div class="carrousel-container margin-top padding-bottom">
      <template v-if="!loading">
        <SpecialityCard
          v-for="(item, index) in specialities"
          :key="index"
          :item="item"
          :lang="$store.getters.language.lang"
          @clicked="pushRoute(item)"
        />
      </template>
      <template v-if="loading">
        <Skeleton
          v-for="(v, i) in 10"
          :key="i"
          width="104px"
          height="124px"
          borderRadius="16px"
        />
      </template>
    </div>
  </div>
</template>

<script>
import {
  SpecialityCard,
  Specialities,
  Skeleton,
  Heading
} from '@seliaco/red-panda'
import { HomeEvent } from '@/types/events'

export default {
  name: 'HomeSpecialities',
  components: { SpecialityCard, Skeleton, Heading },
  data () {
    return {
      specialities: null,
      loading: true,
      actions: {
        viewMore: {
          text: this.$translations.general['view-all-alt'],
          callback: () => {
            this.$router.push({ name: 'Search' })
            HomeEvent.clickSeeAll({
              option: 'HomeSpecialities',
              user: this.$store.getters['auth/user']
            })
          }
        }
      }
    }
  },
  mounted () {
    this.getSpecialities()
  },
  methods: {
    getSpecialities () {
      this.loading = true
      if (this.$store.getters['section/data'].speciality) {
        this.specialities = this.$store.getters['section/data'].speciality
        this.loading = false
        return
      }

      const lang = this.$store.getters.language.lang

      Specialities.read({
        columns: `image,name:name_${lang},disabled,key,id`,
        orderBy: {
          column: 'priority',
          params: {
            ascending: false
          }
        },
        eq: {
          hidden: false
        }
      }).then((specialities) => {
        this.specialities = specialities.data

        this.$store.commit('section/SET_SECTIONS', {
          key: 'speciality',
          value: this.specialities
        })
      })

      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    pushRoute (speciality) {
      HomeEvent.specialistOptionClick({
        option: speciality.name,
        optionId: speciality.id,
        user: this.$store.getters['auth/user']
      })

      if (!speciality.disabled) {
        this.$store
          .dispatch('filtersV2/setQuery', {
            values: {
              specialities: [
                {
                  isChecked: true,
                  text: speciality.name,
                  value: speciality.key
                }
              ]
            },
            cleanedRequest: true
          })
          .then(() => {
            this.$router.push({
              name: 'Search'
            })
          })
      }
    }
  }
}
</script>
