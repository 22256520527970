<template>
  <div class="wallet">
    <div class="wallet-content" v-if="hasCredit">
      <div class="body" v-html="config.home" />
      <div
        class="icon-question-mark-off icon-display cursor-pointer text-gray-40"
        @click="showModal = true"
      />
    </div>
    <DialogContainer
      icon="icon-money-on"
      iconColor="purple"
      contentIconBackground="purple"
      :customIcon="true"
      :buttons="[modal.action]"
      :showButtonClose="false"
      @closeDialog="showModal = false"
      @action="showModal = false"
      v-if="showModal"
    >
      <template v-slot:content>
        <div class="heading margin-y" v-html="modal.title" />

        <div class="body margin-bottom-lg" v-html="modal.description" />

        <ListInfo :items="modal.item" />
      </template>
    </DialogContainer>
  </div>
</template>

<script>
import {
  UsersAppVisibility,
  DialogContainer,
  ListInfo
} from '@seliaco/red-panda'

export default {
  name: 'Wallet',
  components: {
    DialogContainer,
    ListInfo
  },
  data () {
    return {
      showModal: false,
      config: null
    }
  },
  created () {
    this.get()
  },
  methods: {
    async get () {
      this.config = await UsersAppVisibility.wallet()
    }
  },
  computed: {
    modal () {
      return this.config?.modal
    },
    hasCredit () {
      if (parseInt(this.config?.balance) === 0) {
        return false
      }

      return this.config?.home
    }
  }
}
</script>

<style lang="sass" scoped>
.wallet
  margin-top: 12px
  margin-left: 16px
  @media(min-width: 1268px)
    margin-left: 0

  &-content
    display: flex
    align-items: center
    gap: 8px
    .icon-display
      @media(max-width: 480px)
        font-size: var(--xl)
</style>
