<template>
  <AppointmentCard
    v-selectable-container="{ callback: cardAction.callback, elementLevel: 1 }"
    :image="image"
    :title="title"
    :middle-line="middleLine"
    :bottom-line="bottomLine"
    :button-action="buttonAction"
    :active="active"
    :service-icon="serviceIcon"
    :arrow-action="cardAction"
  />
</template>

<script>
import { AppointmentCard, selectableContainer } from '@seliaco/red-panda'
import AppointmentDetailMixin from '@/mixins/appointment-detail-mixin'
import { AppointmentFlowEvent } from '@/types/events'

export default {
  name: 'UsersAppointmentCard',
  mixins: [AppointmentDetailMixin],
  props: {
    origin: {
      type: String,
      default: 'agenda'
    },
    appointment: {
      id: String,
      starts_at: String,
      virtual: Boolean,
      status: String,
      price: Number,
      service: {
        duration: Number,
        type: String,
        icon_name: String
      },
      specialist: {
        id: String,
        user: {
          first_name: String,
          last_name: String,
          profile_picture: String
        }
      }
    },
    detailIsEnabled: {
      type: Boolean,
      default: true
    }
  },
  components: {
    AppointmentCard
  },
  directives: { selectableContainer },
  data () {
    return {
      cardAction: {
        callback: () => {
          let queryExtra = null

          if (this.origin === 'shared-account') {
            queryExtra = {
              origin: this.origin,
              back: this.$route.fullPath
            }
          }

          this.callSegmentEvent()
          this.$router.push({
            name: 'AppointmentDetails',
            params: {
              id: this.appointment.id
            },
            query: {
              ...queryExtra
            }
          })
        }
      },
      option: null
    }
  },
  methods: {
    callSegmentEvent () {
      this.getSegmentData(this.appointment)
    },
    getSegmentData (appointment) {
      const body = {
        specialist_id: appointment.specialist.id,
        specialist_name: `${appointment.specialist.user.first_name} ${appointment.specialist.user.last_name}`,
        service: appointment.service.type,
        appointment_status: appointment.status,
        appointment_details: {
          id: appointment.id,
          starts_at: appointment.starts_at,
          transactions: appointment.transactions,
          reason: appointment.reason,
          virtual_modality: appointment.virtual
        },
        option: this.buttonAction?.text
          ? this.buttonAction.text
          : 'Appointment details',
        origin: this.$route.name
      }
      AppointmentFlowEvent.ApptCard_Click(body)
    }
  },
  computed: {
    image () {
      return this.appointment.specialist.user.profile_picture
    },
    title () {
      const user = this.appointment.specialist.user

      return `${user.first_name} ${user.last_name}`
    },
    middleLine () {
      const timeZone = this.$store.getters.userTimezone
      const startsAt = this.$moment(this.appointment.starts_at).tz(timeZone)

      if (!this.alreadyStarted() && this.isAboutToStart()) {
        const fromNow = startsAt.fromNow()

        return `${this.$translations.general.starts} <span style="color: var(--purple)">${fromNow}</span>`
      }

      const date = this.$moment(this.appointment.starts_at)
        .tz(timeZone)
        .format('D MMMM, ddd • h:mm a')

      const duration = this.appointment.service.duration

      return duration ? `${date} (${duration}min)` : `${date}`
    },
    bottomLine () {
      const status = this.$translations.status[this.appointment.status]
      const virtual =
        this.$translations.appointments.virtual[this.appointment.virtual]
      const redStatus = [
        'NO_ATTEND_USER',
        'NO_ATTEND_SPECIALIST',
        'NON_COMPLETED',
        'NO_ATTEND_USER_WITH_RESCHEDULE',
        'CANCELED',
        'EXPIRED'
      ]

      const alertStatus = ['PENDING', 'PAYMENT_DECLINED']

      if (redStatus.includes(this.appointment.status)) {
        return `${virtual} • <span style="color: var(--red);">${status}</span>`
      }

      if (alertStatus.includes(this.appointment.status)) {
        return `
          <div style="display: flex; gap: 2px;">
            <span>${virtual} •</span>
            <span class="icon-warning-circle-on" style="color: var(--yellow); font-size: var(--base)"></span>
            <span>${status}</span>
          </div>
        `
      }

      return `${virtual} • ${status}`
    },
    buttonAction () {
      let queryExtra = null

      if (this.origin === 'shared-account') {
        return null
        // TODO: add payment flow for shared account
        // queryExtra = {
        //   origin: 'shared-account',
        //   sharedAccountMemberId: this.$route.params.id
        // }
      }

      switch (this.appointment.status) {
        case 'PENDING':
          return {
            text: this.$translations['appointment-actions'].PENDING,
            callback: () => {
              this.callSegmentEvent()
              this.$router.push({
                name: 'CheckOut',
                params: {
                  id: this.appointment.id,
                  type: 'APPOINTMENT'
                },
                query: {
                  specialistId: this.appointment.specialist.id,
                  serviceType: this.appointment.service.type,
                  pay: true
                }
              })
            }
          }

        case 'PAYMENT_DECLINED':
          return {
            text: this.$translations['appointment-actions'].PAYMENT_DECLINED,
            callback: () => {
              this.callSegmentEvent()
              this.$router.push({
                name: 'CheckOut',
                params: {
                  id: this.appointment.id,
                  type: 'APPOINTMENT'
                },
                query: {
                  specialistId: this.appointment.specialist.id,
                  serviceType: this.appointment.service.type
                }
              })
            }
          }

        case 'CONFIRM':
        case 'IN_WAITING_ROOM':
        case 'IN_PROGRESS_WAITING':
        case 'IN_PROGRESS':
          if (this.active && this.appointment.virtual) {
            return {
              text: this.$translations['appointment-actions'].CONFIRM,
              callback: () => {
                this.callSegmentEvent()
                this.$router.push({
                  name: 'Lobby',
                  params: {
                    id: this.appointment.id
                  }
                })
              }
            }
          }

          return null

        case 'NO_ATTEND_USER':
          return {
            text: this.$translations['appointment-actions'].NO_ATTEND_USER,
            callback: () => {
              this.callSegmentEvent()
              this.$router.push({
                name: 'Schedule',
                query: {
                  specialistId: this.appointment.specialist.id,
                  back: this.$route.fullPath
                }
              })
            }
          }

        case 'NO_ATTEND_SPECIALIST':
        case 'NON_COMPLETED':
        case 'NO_ATTEND_USER_WITH_RESCHEDULE':
          return {
            text: this.$translations['appointment-actions'].NON_COMPLETED,
            callback: () => {
              this.callSegmentEvent()
              this.$globalEvent.$emit('modal/reschedule', {
                showDialog: { reschedule: { open: true, close: false } },
                appointment: this.appointment,
                query: {
                  ...queryExtra
                }
              })
            }
          }
      }

      return null
    },
    active () {
      const preAllowedStatus = [
        'IN_WAITING_ROOM',
        'IN_PROGRESS_WAITING',
        'IN_PROGRESS'
      ]

      if (preAllowedStatus.includes(this.appointment.status)) {
        return true
      }

      if (!this.isAboutToStart()) {
        return null
      }

      const allowedStatus = ['CONFIRM']

      return allowedStatus.includes(this.appointment.status)
    },
    serviceIcon () {
      return this.appointment.service.icon_name
    }
  }
}
</script>
