<template>
  <div class="padding-x padding-bottom margin-top">
    <Heading
      :text="$translations.home['content-library'].title"
      :action="actions.viewMore"
    />

    <div class="library-content margin-top">
      <template v-if="!loading && blogs">
        <DisplayCard
          v-for="(item, index) in blogs"
          :key="index"
          :display-config="displayConfig(item)"
          :duration-icon="item.content_format.icon"
          @click="onClick(item)"
        />
      </template>

      <template v-if="loading">
        <div v-for="(v, i) in 3" :key="i">
          <Skeleton
            class="margin-top-xs"
            width="240px"
            height="120px"
            borderRadius="16px"
          />
          <Skeleton
            class="margin-top-xs"
            width="240px"
            height="44px"
            borderRadius="16px"
          />
          <Skeleton
            class="margin-top-xs"
            width="140px"
            height="16px"
            borderRadius="16px"
          />
          <Skeleton
            class="margin-top-xs"
            width="180px"
            height="15px"
            borderRadius="16px"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { DisplayCard, Skeleton, Heading } from '@seliaco/red-panda'
import { ForYouEvent } from '@/types/events'
import MediaContentMixin from '@/mixins/media-content-mixin'
import { mapGetters } from 'vuex'

export default {
  name: 'ContentLibrary',
  components: { DisplayCard, Skeleton, Heading },
  mixins: [MediaContentMixin],
  data () {
    const actions = {
      viewMore: {
        text: this.$translations.general['view-all-alt'],
        callback: () => {
          this.$router.push({ name: 'ForYou' })
        }
      }
    }

    return {
      actions
    }
  },
  async created () {
    this.$store.dispatch('forYou/list', {
      params: {
        recommended: true
      }
    })
  },
  methods: {
    onClick (item) {
      const body = {
        page: this.$route.name,
        origin: 'Home',
        component: 'Library,Card',
        ...item
      }
      ForYouEvent.contentCardOpen(body)
      this.showDetail(item)
    }
  },
  computed: {
    ...mapGetters({
      blogs: 'forYou/recommend',
      loading: 'forYou/loading'
    }),
    displayConfig () {
      return (value) => {
        return {
          ...value,
          duration: `${value.content_type.text} • ${this.durationFormat(
            value.duration
          )}`
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.library-content
  display: grid
  grid-template-columns: repeat(4, 1fr)
  grid-auto-rows: auto
  gap: 16px

@media (max-width: 840px)
  .library-content
    display: grid
    grid-template-columns: repeat(3, 1fr)

@media (max-width: 580px)
  .library-content
    display: grid
    grid-template-columns: repeat(2, 1fr)
</style>
