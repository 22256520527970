<template>
  <div class="padding-x-zero carrousel" id="services-carrousel" v-if="hasItems">
    <Heading
      :text="$translations.home['services-carrousel'].title"
      :action="actions.viewMore"
      class="padding-x"
    />

    <template v-if="!isMobile">
      <Button
        class="carrousel-button-left"
        type="circle"
        size="circle-small"
        icon="icon-arrow-big-outline-left"
        color="white"
        @clicked="moveCarrousel('prev')"
        v-if="showArrows && enableLeft"
      />
    </template>
    <template v-if="!isMobile">
      <Button
        class="carrousel-button-rigth"
        type="circle"
        size="circle-small"
        icon="icon-arrow-big-outline-right"
        color="white"
        @clicked="moveCarrousel('next')"
        v-if="showArrows && enableRight"
      />
    </template>

    <div
      class="carrousel-wrapper"
      id="carrousel-wrapper"
      :class="{ 'carrousel-scroll': isMobile }"
    >
      <template v-if="!loading && items">
        <div
          @click="onClick(item)"
          v-selectable-container="{ callback: () => $emit('clicked', item) }"
          class="carrousel-item"
          id="carrousel-item"
          v-for="(item, index) in items"
          :key="index"
        >
          <div
            class="carrousel-item-icon-container"
            :style="{ background: serviceStyles[item.icon_name].background }"
          >
            <span
              class="icon"
              :class="serviceStyles[item.icon_name].iconName"
              :style="{ color: serviceStyles[item.icon_name].color }"
            ></span>
          </div>
          <div class="body-small" v-text="truncate(item[`name_${lang}`])" />
        </div>
      </template>

      <template v-if="loading && hasItems">
        <div class="margin-left" v-for="(v, i) in 10" :key="i">
          <Skeleton
            class="margin-top-xs"
            width="128px"
            height="124px"
            borderRadius="16px"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {
  Skeleton,
  Button,
  selectableContainer,
  ServiceIcons,
  Heading
} from '@seliaco/red-panda'
import ResponsiveMixin from '@/mixins/responsive.mixin'
import { HomeEvent } from '@/types/events'
import SliderScrollMixin from '@/mixins/slider-scroll-mixin'
import { mapGetters } from 'vuex'

export default {
  name: 'ServicesCarrousel',
  mixins: [SliderScrollMixin, ResponsiveMixin],
  directives: { selectableContainer },
  components: {
    Skeleton,
    Button,
    Heading
  },
  data () {
    return {
      serviceStyles: ServiceIcons,
      carrousel: null,
      hasItems: true,
      actions: {
        viewMore: {
          text: this.$translations.general['see-more'],
          callback: () => {
            this.$router.push({
              name: 'Search'
            })
          }
        }
      },
      lang: 'en'
    }
  },
  async created () {
    this.lang = this.language.lang !== 'pt' ? this.language.lang : 'en'
    await this.$store.dispatch('services/get')
    this.setServices()
  },
  destroyed () {
    this.controller.abort()
  },
  methods: {
    setServices () {
      this.hasItems = this.items.length > 0
      this.setListeners()
    },
    setListeners () {
      this.controller = new AbortController()
      this.carrousel = document.getElementById('services-carrousel')
      const wrapper = document.getElementById('carrousel-wrapper')
      this.maxPosition = this.items.length - 1
      this.enableRight = this.items.length >= 5

      if (this.carrousel) {
        this.carrousel.addEventListener(
          'mouseover',
          () => {
            this.enableLeft = wrapper.scrollLeft > 20
            this.showArrows = true
          },
          { signal: this.controller.signal }
        )
        this.carrousel.addEventListener(
          'mouseleave',
          () => {
            this.showArrows = false
          },
          { signal: this.controller.signal }
        )

        wrapper.addEventListener(
          'scroll',
          (e) => {
            if (!this.isMobile) {
              e.preventDefault()
            }
          },
          { signal: this.controller.signal }
        )
      }
    },
    moveCarrousel (direction) {
      const wrapper = document.getElementById('carrousel-wrapper')
      const itemWidth =
        document.getElementById('carrousel-item').clientWidth + 10

      let operation
      switch (direction) {
        case 'prev':
          operation = itemWidth * this.currentStep - itemWidth
          this.currentStep -= 1
          break
        case 'next':
        default:
          operation = itemWidth * this.currentStep + itemWidth
          this.currentStep += 1
          break
      }

      wrapper.scroll({
        left: operation,
        behavior: 'smooth'
      })

      this.enableLeft = operation > 10
      this.enableRight = operation < wrapper.scrollWidth - wrapper.clientWidth
    },
    onClick (item) {
      const body = {
        optionId: item.id,
        option: item.name_en
      }
      HomeEvent.Home_Services_Click(body)

      this.$router.push({
        name: 'Search',
        query: {
          serviceId: item.id,
          serviceName: item[`name_${this.lang}`]
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      language: 'language',
      items: 'services/getServicesTypes',
      loading: 'services/getLoading'
    }),
    truncate () {
      return (value) => {
        if (!value) {
          return ''
        }

        return value.length > 23 ? `${value.slice(0, 23)}...` : value
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.carrousel
  display: flex
  flex-direction: column
  gap: 14px
  position: relative

  &-scroll
    overflow-x: auto !important
    // firefox hide scrollbar
    scrollbar-width: initial
    &::-webkit-scrollbar
      display: block

  &-wrapper
    display: flex
    gap: 10px
    width: 100%
    position: relative
    overflow-x: hidden
    overflow-y: hidden
    height: 135px

    // firefox hide scrollbar
    scrollbar-width: none

    // chrome hide scrollbar
    &::-webkit-scrollbar
      display: none

  &-item
    display: flex
    justify-content: space-between
    align-items: center
    gap: 8px
    flex-direction: column
    min-width: 128px
    height: 124px
    border: 2px solid var(--gray-10)
    box-shadow: var(--shadow-gray)
    border-radius: var(--border-2)
    text-align: center
    max-width: 128px

    &:first-child
      margin-left: 16px

    &:last-child
      margin-right: 16px

    &.loader
      align-self: center

    &-icon-container
      min-width: 48px
      min-height: 48px
      border-radius: 100%
      display: flex
      align-items: center
      justify-content: center
      .icon
        align-self: center
        font-size: var(--xl)

  &-button-rigth,
  &-button-left
    position: absolute
    top: 50%
    transform: translateY(-30%)
    z-index: 2

  &-button-rigth
    right: -10px
  &-button-left
    left: -10px

@media (min-width: 1260px)
  .carrousel
    .padding-x
      padding-left: 0px!important
    &-item
      &:first-child
        margin-left: 0px
      &:last-child
        margin-right: 0px
</style>
