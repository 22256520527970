<template>
  <div class="selia-notifications margin-bottom margin-x rounded-xl body-small padding-all" :style="{ 'background-color': '#ffcd99' }" v-html="message" v-if="message"></div>
</template>

<script>
import { Settings } from '@seliaco/red-panda'

export default {
  name: 'SeliaNotifications',
  data () {
    return {
      message: null
    }
  },
  mounted () {
    this.getBlog()
  },
  methods: {
    getBlog () {
      Settings.read({
        columns: `
          value
        `,
        eq: {
          key: 'SELIA_NOTIFICATION'
        }
      }).then((response) => {
        if (response.data[0]?.value?.length > 1) {
          this.message = response.data[0].value
        }
      })
    }
  }
}
</script>

<style lang="sass">
.selia-notifications > p
  margin-bottom: 4px
</style>
