<template>
  <DialogContainer
    :title="modalTitle"
    @closeDialog="$emit('close')"
  >
    <template v-slot:content>
      <div v-if="data">
        <StatusBar :score="data.score" :scale="data.scale" :type="data.type" :lang="$store.getters.language.lang" />
        <div class="checkin-text">
          <div class="content-title">
            {{ $translations['check-in'].modal.content }}
          </div>
          <div class="body" data-hj-suppress>
            {{ modalText }}
          </div>
        </div>
        <Button
          class="margin-top"
          :text="$translations['check-in'].modal.button"
          :type="'outline'"
          :color="'gray'"
          @clicked="$emit('close')"
        />
      </div>

    </template>
  </DialogContainer>
</template>

<script>
import { DialogContainer, Button, StatusBar } from '@seliaco/red-panda'

export default {
  name: 'CheckInModal',
  components: {
    DialogContainer,
    Button,
    StatusBar
  },
  props: {
    data: Object
  },
  computed: {
    modalTitle () {
      return this.$translations['check-in'].modal.title
        .replaceAll('{type}', this.$translations['check-in'].type[this.data?.type.toLowerCase()])
        .replaceAll('{month}', this.data?.month)
    },
    modalText () {
      return this.data.metadata.find(answer => answer.type === 'TEXT').value
    }
  }
}
</script>

<style lang="sass" scoped>
.checkin-text
  display: flex
  gap: 10px
  flex-direction: column
  align-items: baseline
  margin: 16px 0
</style>
