<template>
  <div class="segment swipper-carrousel" v-if="!loading">
    <SwipperCarrousel
      :show-arrows="false"
      :show-pagination="!isMobile"
      :slides-per-view="isMobile ? 1.25 : 1"
      :space-between="16"
      :loopedSlides="banners.length"
      :autoplay="false"
      class="segment-content swipper-carrousel-container"
      bounce
      :show-bullets="!isMobile"
    >
      <template v-slot:slides>
        <div
          id="crosselling"
          class="slider-content swiper-slide"
          v-for="(banner, index) in banners"
          :key="index"
        >
          <CrossellingBanner
            :text="banner.text"
            :price="banner.price"
            :country="$store.getters['auth/user'].country"
            :lang="$store.getters.language.lang"
            :action="banner.action"
            :layout="banner.layout"
            :image="banner.image"
            :segment="banner.segment"
            :router="$router"
            :context="user"
            :segment-instance="$segment"
            @action-event="handleEventActions($event)"
          />
        </div>
      </template>
    </SwipperCarrousel>
  </div>
  <div class="segment swipper-carrousel margin-x" v-else>
    <!-- skeleton loader-->
    <Skeleton class="segment-content" height="112px" borderRadius="16px" />
  </div>
</template>

<script>
import { CrossellingBanner, Skeleton } from '@seliaco/red-panda'
import SwipperCarrousel from '@/components/misc/SwipperCarrousel.vue'
import ResponsiveMixin from '@/mixins/responsive.mixin'
import { mapGetters } from 'vuex'

export default {
  name: 'CrossellingCarrousel',
  components: {
    CrossellingBanner,
    SwipperCarrousel,
    Skeleton
  },
  mixins: [ResponsiveMixin],
  data () {
    return {
      banners: [],
      loading: true
    }
  },
  mounted () {
    if (this.settings.banners) {
      this.filterBanners()
    } else {
      this.$store.watch(
        () => this.settings.banners,
        () => {
          this.filterBanners()
        }
      )
    }
  },
  methods: {
    filterBanners () {
      this.banners = this.settings.banners.filter((banner) =>
        banner.display.status.includes(this.user.status)
      )
      this.banners = this.banners.filter((banner) =>
        banner.display['show-only-after']
          ? this.filterByVersion(banner.display['show-only-after'])
          : true
      )
      this.loading = false
    },
    filterByVersion (validVersion) {
      const currentVersionNumbers = this.$version.split('.')
      const validVersionNumbers = validVersion.split('.')

      const result = currentVersionNumbers
        .map((current, i) => current.localeCompare(validVersionNumbers[i]))
        .find((r) => r !== 0)

      return result === 1
    },
    handleEventActions (event) {
      switch (event.type) {
        case 'PACKAGES':
          if (event.action === 'SPECIALIST_PACKAGES') {
            this.$router.push({
              name: 'Packages',
              query: {
                specialist: JSON.stringify({
                  id: this.nextAppointment.specialist.id
                }),
                back: this.$route.fullPath
              }
            })
          } else {
            this.$router.push({
              name: 'ServicePackages',
              query: {
                back: this.$route.fullPath
              }
            })
          }
      }
    }
  },
  computed: {
    ...mapGetters({
      settings: 'settings/getSettings',
      nextAppointment: 'nextAppointment',
      user: 'auth/user'
    })
  }
}
</script>

<style lang="sass" scoped>
.swipper-carrousel
  padding-right: 0 !important
  padding-left: 0 !important
  min-height: 112px
  &-container
    padding: 0 !important

@media (max-width: 840px)
  .swiper-slide
    &:first-child
      margin-left: 16px
</style>
