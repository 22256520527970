<template>
  <div
    class="segment"
    v-if="showContent"
  >
    <div class="segment-content">
      <Heading
        :text="$translations.navbar['my-conferences']"
        :action="actions.viewMore"
      />

      <div class="margin-top margin-bottom-sm">
        <ConferenceAppointmentCard
          :conference="conference"
          :action="actions.conferenceCard"
          :active="setActive"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { UsrConferences, Heading } from '@seliaco/red-panda'
import ConferenceAppointmentCard from '@/components/appointment/ConferenceCard'
import { Browser } from '@capacitor/browser'
import ConferencesSettingMixin from '@/mixins/conferences-setting-mixin'

export default {
  name: 'HomeNextConference',
  components: {
    Heading,
    ConferenceAppointmentCard
  },
  mixins: [ConferencesSettingMixin],
  data () {
    return {
      loading: true,
      conference: null,
      firstDate: null,
      secondDate: null,
      actions: {
        viewMore: {
          text: this.$translations.general['see-more'],
          callback: () => {
            this.$router.push({
              name: 'Agenda',
              query: {
                ...this.$route.query,
                tab: 'conferences'
              }
            })
          }
        },
        conferenceCard: null
      }
    }
  },
  methods: {
    mount () {
      this.getConferences()
    },
    getConferences () {
      this.loading = true
      UsrConferences.list('user')
        .then((response) => {
          this.conference = response.data.reverse()[0]

          const date = this.conference?.start_date.split('+')[0]

          this.firstDate = this.$moment(date).tz(
            this.$store.getters.userTimezone
          )

          this.secondDate = this.$moment(date)
            .tz(this.$store.getters.userTimezone)
            .add(this.conference?.duration, 'minute')

          if (this.$moment(new Date()).isAfter(this.secondDate)) {
            this.conference = null
          }

          if (
            this.$moment(new Date()).isSameOrAfter(this.firstDate) &&
            this.$moment(new Date()).isBefore(this.secondDate)
          ) {
            this.actions.conferenceCard = {
              text: this.$translations.conferences.buttons.enter,
              callback: this.openBrowser
            }
          }
        })
        .catch(() => null)
      this.loading = false
    },
    goDetailConference (item) {
      this.$router.push({
        name: 'ConferenceDetail',
        params: {
          id: item.id
        }
      })
    },
    openBrowser () {
      Browser.open({
        url: this.conference?.room_link
      })
    }
  },
  computed: {
    isVisible () {
      if (!this.conference) {
        return false
      }

      return this.$moment(new Date()).isBefore(this.secondDate)
    },
    setActive () {
      return (
        this.$moment(new Date()).isSameOrAfter(this.firstDate) &&
        this.$moment(new Date()).isBefore(this.secondDate)
      )
    },
    showContent () {
      return this.isVisible && this.$store.getters.settings && this.$store.getters.settings.conferenceModule && !this.loading
    }
  }
}
</script>
