<template>
  <div
    class="padding-all"
    v-if="
      $store.getters['settings/getSettings'].conferenceModule &&
      conferences &&
      conferences.length
    "
  >
    <Heading
      :text="$translations.conferences['next-conferences']"
      :action="actions.viewMore"
    />

    <div class="carrousel-container margin-top padding-bottom">
      <ConferenceCard
        v-selectable-container
        class="carrousel-item"
        :style="width"
        v-for="(item, index) in conferences"
        :key="index"
        :conference="item"
        @click="goDetailConference"
      />
    </div>
  </div>
</template>

<script>
import {
  ConferenceCard,
  Heading,
  Subscriber,
  selectableContainer,
  ResponsiveMixin
} from '@seliaco/red-panda'

import { HomeEvent } from '@/types/events'
import { mapGetters } from 'vuex'

export default {
  name: 'HomeConferences',
  components: {
    Heading,
    ConferenceCard
  },
  mixins: [ResponsiveMixin],
  directives: {
    selectableContainer
  },
  data () {
    return {
      actions: {
        viewMore: {
          text: this.$translations.general['see-more'],
          callback: () => {
            this.$router.push({
              name: 'Conferences'
            })
            HomeEvent.clickSeeAll({
              option: 'HomeConferences',
              user: this.$store.getters['auth/user']
            })
          }
        }
      },
      subscriber: null
    }
  },
  beforeUnmount () {
    this.subscriber.removeSubscription()
  },
  mounted () {
    if (this.$store.getters['settings/getSettings'].conferenceModule) {
      this.onMounted()
    } else {
      this.$store.watch(
        () => this.$store.getters['settings/getSettings'],
        () => {
          this.onMounted()
        }
      )
    }
  },
  methods: {
    onMounted () {
      if (this.$store.getters['settings/getSettings']) {
        this.getConferences()
        this.subscribeToChannel()
      }
    },
    subscribeToChannel () {
      this.subscriber = Subscriber.createChannel('conferences')
      this.subscriber.updateEvent(this.receiveMessage)
    },
    receiveMessage (params) {
      this.conferences = this.conferences.map((i) => {
        if (params.new.id === i.id) {
          i.ability = params.new.ability
          i.status = params.new.status
        }
        return i
      })
    },
    async getConferences () {
      await this.$store.dispatch('conferences/getHomeConferences', {
        user: this.user
      })
    },
    goDetailConference (item) {
      HomeEvent.eventsOptionClick({
        option: item.name,
        optionId: item.id,
        user: this.$store.getters['auth/user']
      })
      this.$router
        .push({
          name: 'ConferenceDetail',
          params: {
            id: item.id
          }
        })
        .catch(() => {})
    }
  },
  computed: {
    ...mapGetters({
      conferences: 'conferences/getHomeConferences',
      user: 'auth/user'
    }),
    width: {
      get () {
        let width = '340px'
        if (this.windowWidth < 840) {
          width = '300px'
        }

        return {
          '--carrousel-item-width': width
        }
      },
      set () {
        let width = '340px'
        if (this.windowWidth < 840) {
          width = '300px'
        }

        return {
          '--carrousel-item-width': width
        }
      }
    }
  }
}
</script>
