<template>
  <div class="container" :style="containerStyles">
    <div class="header" v-if="!isEntryType">
      <div
        class="small text-blue"
        v-text="$translations.diary['mood-banner'].title"
      />
      <div
        class="heading-mate margin-bottom-lg"
        v-text="$translations.diary['mood-banner'].heading"
      />
    </div>
    <div class="options">
      <MoodButton
        v-for="(option, index) in options"
        :key="index"
        :option="option"
        @clicked="clickButton"
        :class="{
          active: optionSelected && optionSelected.code === option.code
        }"
      />
    </div>
    <div
      v-if="optionSelected"
      class="content-title margin-top"
      v-text="optionText(optionSelected.feeling)"
    />
  </div>
</template>

<script>
import MoodButton from '@/views/diary/components/MoodButton'
import { moodBannerEmojis } from '@/views/diary/configuration/configuration'
import { DiaryEvents } from '@/types/events'

export default {
  name: 'MoodBanner',
  components: { MoodButton },
  props: {
    labelStyle: {
      type: String,
      default: 'gray'
    },
    isEntryType: Boolean,
    back: {
      type: String,
      default: 'Diary'
    }
  },
  data () {
    return {
      optionSelected: null,
      options: moodBannerEmojis
    }
  },
  methods: {
    clickButton (option) {
      this.optionSelected = option
      this.$router.push({
        name: 'DiaryEntry',
        query: {
          ...this.$route.query,
          mood: JSON.stringify(option),
          back: this.back
        }
      }).then(() => {
        const body = {
          user: this.$store.getters['auth/user'],
          origin: this.back,
          component: 'Banner',
          mood: this.optionSelected.score

        }
        DiaryEvents.Diary_Click_Create(body)
      })
    }
  },
  mounted () {
    if (this.$route.query.mood) {
      this.optionSelected = JSON.parse(this.$route.query?.mood)
    }
  },
  computed: {
    optionText () {
      return (feeling) => {
        return this.$translations.diary['mood-banner'].feelings[feeling]
      }
    },
    containerStyles () {
      if (this.labelStyle === 'gray') {
        return {
          '--border-style': '2px solid var(--gray-10)',
          '--shadow-style': 'var(--shadow-gray)'
        }
      } else {
        return {
          '--border-style': 'unset',
          '--shadow-style': 'var(--shadow-purple)'
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.container
  display: flex
  flex-direction: column
  align-items: center
  background: var(--white)
  border: var(--border-style)
  box-shadow: var(--shadow-style)
  border-radius: var(--border-3)
  padding: 16px
  max-width: 480px
  .header
    display: flex
    flex-direction: column
    align-items: center
  .options
    display: flex
    align-items: center
    height: 56px
    gap: 10px

@media (min-width: 840px)
  .container
    .options
      gap: 28px
</style>
