<template>
  <div v-if="show">
    <template v-if="appointment">
      <div class="segment">
        <div class="segment-content">
          <!-- title and view button -->
          <Heading
            :text="$translations.appointments['upcoming-appointments']"
            :action="actions.viewMore"
          />

          <!-- appointments -->
          <div class="margin-top">
            <UsersAppointmentCard :appointment="appointment" />
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="segment">
        <div class="segment-content">
          <!-- title and view button -->
          <Heading
            :text="$translations.appointments['upcoming-appointments']"
            :action="actions.viewMore"
            class="margin-bottom"
          />

          <Button
            v-if="userState === stateEnum.waiting_for_first_contact"
            :text="
              $translations.appointments['empty-state'].buttons[
                'search-specialists'
              ]
            "
            @clicked="
              $router.push({ name: 'Search', query: { on_focus: true } })
            "
          />

          <div
            class="suggestion-wrapper padding-all margin-bottom"
            v-if="specialist"
          >
            <div class="suggestion-content margin-bottom">
              <img
                class="suggestion-content_img"
                :src="avatar"
                :alt="specialist.first_name"
              />
              <div>
                <div class="body">
                  {{ $translations['data-zero']['suggestion-text'] }}
                </div>
                <div class="heading-small">
                  {{ specialist.first_name }} {{ specialist.last_name }}
                </div>
              </div>
            </div>

            <Button
              :text="
                $translations['recommended-specialist']['book-appointment']
              "
              @clicked="schedule"
            />
          </div>
          <!-- changes specialists -->
          <div class="change-specialist-wrapper margin-top-xl margin-bottom">
            <div class="body">
              {{ $translations['data-zero']['change-specialist'] }}
            </div>
            <div
              class="content-title text-purple cursor-pointer"
              @click="navigateMatching"
            >
              {{ $translations['data-zero']['button-change-specialist'] }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { Heading, Button } from '@seliaco/red-panda'
import { AppointmentStatus } from '@/constants/appointment'
import { mapGetters } from 'vuex'
import { HomeEvent, SpecialistProfileEvent } from '@/types/events'
import UsersAppointmentCard from '@/components/appointment/AppointmentCard'
import { userStatus } from '@/types/state'

export default {
  name: 'NextAppointment',
  components: { UsersAppointmentCard, Heading, Button },
  props: {
    userState: String
  },
  data () {
    return {
      stateEnum: userStatus,
      appointmentStatus: AppointmentStatus,
      appointment: null,
      specialist: null,
      show: false,
      actions: {
        viewMore: {
          text: this.$translations.general['view-all-alt'],
          callback: () => {
            this.$router.push({
              name: 'Agenda'
            })
            HomeEvent.clickSeeAll({
              option: 'Appointment',
              user: this.$store.getters['auth/user']
            })
          }
        }
      }
    }
  },
  async created () {
    this.handleRefreshAppointment()
    await this.$store.dispatch('setNextAppointment')
    this.getNextAppointment()
  },
  methods: {
    getNextAppointment () {
      const { appointment, specialist } = this.nextAppointment

      this.appointment = appointment ? { ...appointment } : null
      this.specialist = specialist ? { ...specialist } : null

      const validState = [
        this.stateEnum.account_created,
        this.stateEnum.waiting_for_orientation
      ]

      if (!this.appointment && !this.specialist) {
        this.show = false
      } else if (!validState.includes(this.userState)) {
        this.show = true
      } else if (
        this.userState === this.stateEnum.waiting_for_orientation &&
        this.appointment
      ) {
        this.show = true
      }
    },
    handleRefreshAppointment () {
      this.$globalEvent.$on('refresh/appointment', () => {
        if (this.$router.history.current.name === 'Home') {
          this.getNextAppointment()
        }
      })
    },
    schedule () {
      SpecialistProfileEvent.clickSchedule({
        user: this.$store.getters['auth/user'],
        specialist: {
          firstName: this.specialist.first_name,
          id: this.specialist.id,
          lastName: this.specialist.last_name,
          profilePicture: this.specialist.profile_picture
        }
      })
      this.$router.push({
        name: 'Schedule',
        query: {
          specialistId: this.specialist.id,
          maxDiscount: this.discountPercent,
          back: this.$route.fullPath
        }
      })
    },
    openChangeSpecialistDialog () {
      this.$globalEvent.$emit('modal/change-specialist', {
        showDialog: { changeSpecialist: { open: true, close: false } }
      })
    },
    navigateMatching () {
      this.$router.push({ name: 'QuestionnaireContainer' })
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      nextAppointment: 'nextAppointment'
    }),
    avatar () {
      return (
        this.specialist.profile_picture || require('@/assets/images/avatar.png')
      )
    }
  }
}
</script>

<style lang="sass">
.datazero-appointment-empty
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  background: var(--purple-10)
  gap: 13px
  border-radius: 16px

.suggestion-wrapper
  background: var(--purple-5)
  border: 2px solid var(--purple-20)
  border-radius: 16px
  filter: drop-shadow(0px 5px 20px rgba(131, 80, 230, 0.2))

.suggestion-content
  display: flex
  gap: 16px
  align-items: center

  &_img
    width: 56px
    height: 56px
    border-radius: 50%
    object-fit: cover

.change-specialist-wrapper
  text-align: center
</style>
