<template>
  <div :class="{ 'padding-all': sectionPadding }" class="programs">
    <Heading
      :title-icon="titleIcon"
      :text="$translations.programs.title"
      :action="action"
    />
    <div class="carrousel-container margin-top padding-bottom" v-if="!loading">
      <ProgramCard
        :program="program"
        class="carrousel-item"
        :style="width"
        :lang="$store.getters.language.lang"
        :country="user.country"
        v-for="(program, index) of programs"
        :key="index"
        @click="goProgramDetail(program)"
      />
    </div>
  </div>
</template>

<script>
import { Heading, ProgramCard, ResponsiveMixin } from '@seliaco/red-panda'
import { mapGetters } from 'vuex'
import { ProgramsEvent } from '@/types/events'

export default {
  name: 'Programs',
  components: { Heading, ProgramCard },
  props: {
    origin: String,
    sectionPadding: {
      type: Boolean,
      default: true
    },
    titleIcon: String,
    action: Object
  },
  mixins: [ResponsiveMixin],
  methods: {
    goProgramDetail (program) {
      ProgramsEvent.Programs_Button_Clicked({
        origin: this.$route.name,
        destination: 'Program detail',
        program: program.subtitle
      })

      this.$router.push({
        name: 'ProgramDetail',
        params: {
          id: program.id
        },
        query: {
          back: this.$route.fullPath
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      programs: 'programs/getPrograms',
      loading: 'programs/getLoading'
    }),
    width: {
      get () {
        let width = '340px'
        if (this.windowWidth < 840) {
          width = '300px'
        }

        return {
          '--carrousel-item-width': width
        }
      },
      set () {
        let width = '340px'
        if (this.windowWidth < 840) {
          width = '300px'
        }

        return {
          '--carrousel-item-width': width
        }
      }
    }
  }
}
</script>
