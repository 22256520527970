export default {
  data () {
    return {
      container: null,
      showArrows: false,
      maxPosition: null,
      enableLeft: false,
      enableRight: false,
      currentStep: 0,
      touchstartX: 0,
      touchendX: 0,
      controller: null,
      elementClickTime: null,
      scrollTimer: null
    }
  },
  mounted () {
    this.container = document.getElementById(this.elementId)
    this.setConfig()
    this.setListeners()
  },
  destroyed () {
    this.controller.abort()
  },
  methods: {
    slide (direction) {
      let left = null

      clearTimeout(this.elementClickTime)

      const { clientWidth } = this.container

      switch (direction) {
        case 'prev':
          left = clientWidth * this.currentStep - clientWidth
          break
        case 'next':
        default:
          left = clientWidth * this.currentStep + clientWidth
          break
      }

      this.container.scroll({
        left,
        behavior: 'smooth'
      })

      this.setBulletPosition()
    },
    setBulletPosition () {
      const scrollLeftMax =
        this.container.scrollWidth - this.container.clientWidth
      const scrollLeft = this.container.scrollLeft
      let scrollPositionIndex = scrollLeftMax / scrollLeft

      if (scrollPositionIndex === Infinity) {
        scrollPositionIndex = this.items.length
      }

      let steps = []

      this.items.forEach((o, i) => {
        steps.push(i + 1)
      })

      steps = steps.reverse()

      this.currentStep = steps.findIndex(
        (o) => o === Math.ceil(scrollPositionIndex)
      )
      this.enableLeft = this.currentStep > 0
      this.enableRight = this.currentStep !== this.maxPosition
    },
    setListeners () {
      this.controller = new AbortController()

      this.container.addEventListener(
        'scroll',
        (e) => {
          e.preventDefault()
          clearTimeout(this.scrollTimer)
          this.scrollTimer = setTimeout(() => {
            this.handleDirectionChangeScroll()
          }, 100)
        },
        { signal: this.controller.signal }
      )

      this.container.addEventListener(
        'touchstart',
        (e) => {
          e.preventDefault()
          this.touchstartX = e.changedTouches[0].screenX
        },
        { signal: this.controller.signal }
      )

      this.container.addEventListener(
        'touchend',
        (e) => {
          e.preventDefault()
          this.touchendX = e.changedTouches[0].screenX
          this.handleDirectionChangeTouch()
        },
        { signal: this.controller.signal }
      )

      this.container.addEventListener(
        'mouseover',
        () => {
          this.showArrows = true
        },
        { signal: this.controller.signal }
      )

      this.container.addEventListener(
        'mouseleave',
        () => {
          this.showArrows = false
        },
        { signal: this.controller.signal }
      )
    },
    setConfig () {
      this.maxPosition = this.items.length - 1
      this.enableRight = this.items.length > 1
    },
    handleDirectionChangeTouch () {
      if (this.touchendX < this.touchstartX && this.enableRight) {
        if (this.currentStep === this.maxPosition) {
          return
        }

        this.slide('next')
      }

      if (this.touchendX > this.touchstartX) {
        if (this.currentStep === 0) {
          return
        }

        this.slide('prev')
      }
    },
    handleDirectionChangeScroll () {
      clearTimeout(this.elementClickTime)

      this.setBulletPosition()
    }
  }
}
