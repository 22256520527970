<template>
  <div class="home-skeleton">
    <!-- crosselling carrousel -->
    <div class="segment swipper-carrousel margin-x">
      <Skeleton class="segment-content" height="112px" borderRadius="16px" />
    </div>

    <!-- shortcuts -->
    <div class="swipper-carrousel margin-x segment">
      <Skeleton class="segment-content" height="220px" borderRadius="16px" />
    </div>

    <!-- MoodBanner -->
    <div class="segment">
      <Skeleton
        class="segment-content"
        width="100%"
        height="140px"
        borderRadius="16px"
      />
    </div>

    <!-- PackagePromotionBanner -->
    <div class="segment">
      <Skeleton
        class="segment-content"
        width="100%"
        height="140px"
        borderRadius="16px"
      />
    </div>

    <!-- ServicesCarrousel -->
    <div class="header margin-bottom padding-x segment">
      <Skeleton width="200px" height="30px" borderRadius="8px" />
      <Skeleton width="80px" height="30px" borderRadius="8px" />
    </div>
    <div class="carrousel margin-bottom-xl padding-x-sm">
      <div class="margin-left-xs" v-for="(v, i) in 10" :key="i">
        <Skeleton
          class="margin-top-xs"
          width="128px"
          height="124px"
          borderRadius="16px"
        />
      </div>
    </div>

    <!-- Next Event -->
    <div class="header margin-bottom padding-x segment">
      <Skeleton width="200px" height="30px" borderRadius="8px" />
      <Skeleton width="80px" height="30px" borderRadius="8px" />
    </div>
    <div class="carrousel margin-bottom-xl padding-x">
      <div class="margin-left-xs" v-for="(v, i) in 3" :key="i">
        <Skeleton
          class="margin-top-xs"
          width="340px"
          height="200px"
          borderRadius="16px"
        />
      </div>
    </div>

    <!-- For you -->
    <div class="header padding-x segment">
      <Skeleton width="200px" height="30px" borderRadius="8px" />
      <Skeleton width="80px" height="30px" borderRadius="8px" />
    </div>
    <div class="margin-bottom-lg margin-x library-content">
      <div v-for="(v, i) in 8" :key="i">
        <Skeleton
          class="margin-top-xs"
          width="240px"
          height="120px"
          borderRadius="16px"
        />
        <Skeleton
          class="margin-top-xs"
          width="240px"
          height="44px"
          borderRadius="16px"
        />
        <Skeleton
          class="margin-top-xs"
          width="140px"
          height="16px"
          borderRadius="16px"
        />
        <Skeleton
          class="margin-top-xs"
          width="180px"
          height="15px"
          borderRadius="16px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Skeleton } from '@seliaco/red-panda'
export default {
  name: 'HomeSkeleton',
  components: { Skeleton }
}
</script>

<style lang="sass" scoped>
.home-skeleton
  display: flex
  flex-direction: column
.swipper-carrousel
  padding-right: 0 !important
  padding-left: 0 !important
  min-height: 112px
.library-content
  display: grid
  grid-template-columns: repeat(4, 1fr)
  grid-auto-rows: auto
  gap: 16px

@media (max-width: 840px)
  .library-content
    display: grid
    grid-template-columns: repeat(3, 1fr)

@media (max-width: 580px)
  .library-content
    display: grid
    grid-template-columns: repeat(2, 1fr)
.default
  margin: 0 auto
  display: flex
  flex-direction: column
  align-items: center
.carrousel
  display: flex
.header
  display: flex
  justify-content: space-between
</style>
