import { render, staticRenderFns } from "./CrossellingCarrousel.vue?vue&type=template&id=7e24307a&scoped=true"
import script from "./CrossellingCarrousel.vue?vue&type=script&lang=js"
export * from "./CrossellingCarrousel.vue?vue&type=script&lang=js"
import style0 from "./CrossellingCarrousel.vue?vue&type=style&index=0&id=7e24307a&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e24307a",
  null
  
)

export default component.exports