<template>
  <div @click="$emit('clicked', option)" class="option-box">
    <div :class="optionBackground(option)">
      <div class="item" v-html="option.code" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MoodButton',
  props: {
    option: Object
  },
  computed: {
    optionBackground () {
      return (option) => {
        return `option option-${option.background}`
      }
    }
  }
}
</script>

<style lang="sass" scoped>
$transition: all .2s ease-in-out

.option-box
  display: flex
  justify-content: center
  width: 48px
  height: 48px
  align-items: center
  border: 2px solid var(--gray-20)
  border-radius: var(--border-1)
  cursor: pointer
  transition: $transition
  &.active
    width: 56px
    height: 56px
    background: var(--purple-5)
    border: 2px solid var(--purple-50)
    box-shadow: 0px 0px 0px 4px #E6DCFA
    .option
      width: 46px
      height: 46px
      transition: $transition
      .item
        font-size: 30px
        transition: $transition
  &:hover
    background: var(--purple-5)
    border: 2px solid var(--purple-50)
  .option
    display: flex
    justify-content: center
    padding: 8px
    width: 38px
    height: 38px
    align-items: center
    border-radius: var(--border-full)
    .item
      font-size: 16px
      font-family: 'Serif', serif
      line-height: 22px
    &-gray
      background: linear-gradient(180deg, rgba(183, 183, 183, 0.5) 0%, rgba(183, 183, 183, 0) 100%)
    &-blue
      background: linear-gradient(180deg, rgba(159, 213, 250, 0.5) 0%, rgba(159, 213, 250, 0) 100%)
    &-purple
      background: linear-gradient(180deg, rgba(219, 208, 237, 0.5) 0%, rgba(219, 208, 237, 0.02) 100%)
    &-green
      background: linear-gradient(180deg, rgba(158, 224, 167, 0.5) 0%, rgba(158, 224, 167, 0) 100%)
    &-yellow
      background: linear-gradient(180deg, #FFF5CC 0%, rgba(255, 245, 204, 0) 100%)
</style>
